import React, { useState, useEffect, useCallback } from 'react';
import Spinner from 'components/common/Spinner';
import { ImageViewer } from "react-image-viewer-dv"
import { getAllFormsTc, getSurveyCode, updateStatusTc, confirmCode } from 'api';
import useSockette from 'hooks/useNewWebSocket';

const statusOptions = [{value: "Pending", label: "Pending"}, {value: "Changes Needed", label: "Changes Needed"},  {value: "Approved", label: "Approve"}];
const nameMapping = {
    'Speed Test': "Speed Test",
    'Survey - FireAlarm Task': "Panel",
    'Survey - SecurityAlarm Task': "Panel"
}

const Report = () => {

    const [activeTab, setActiveTab] = useState('type1');
    const [status, setStatus] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState([]);
    const [openMenu, setOpenMenu] = useState(null);
    const [statusMap, setStatusMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const  [id, setId] = useState(null);
    const [surveyCode, setSurveyCode] = useState(null);

    async function getSubmittedForm(instanceId) {
        try {
            setLoading(true)

            const response = await getAllFormsTc(instanceId || id)

            setData(response.data);
            setActiveTab(response?.data[0]?.id)
            const initialStatusMap = response.data.reduce((acc, item) => {
                acc[item.id] = item.status || 'Pending';
                return acc;
            }, {});
            setStatusMap(initialStatusMap);
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    }

    let {data: socketData, error: socketError, isConnected, sendMessage} = useSockette(`${process.env.REACT_APP_BACKEND_BASE_URL}/v3/real-time?ticket=${id}`);

    async function getSignOutCode(instanceId) {
        try {
            if(surveyCode){
                setIsModalOpen(true)
                return
            }

            setLoading(true)

            const response = await getSurveyCode(instanceId)

            setSurveyCode(response?.data?.code)
            sendMessage({action: "mutation"})
            setIsModalOpen(true)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }   
    }

    async function getConfirmCode(instanceId) {
        try {
            setLoading(true)
           await confirmCode(instanceId)
           sendMessage({action: "mutation"})

            setIsModalOpen(false)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }   
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const instanceId = params.get('instanceId');
        setId(instanceId)

        if (!instanceId) {
            setError("Unable to find survey Instance");
            setLoading(false)
            return;
        }

        getSubmittedForm(instanceId);
    }, []);


    useEffect(() => {
        getSubmittedForm(id)
    }, [socketData])

    const handleStatusChange = async(id, status) => {
        setStatusMap((prevStatusMap) => ({
            ...prevStatusMap,
            [id]: status,
        }));
        try {
            await updateStatusTc(id, {status})
            setLoading(false)

            if(isConnected){
                sendMessage({action: "mutation"})
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    };

    const renderFieldValue = (field, value) => {
        switch (field.type) {
            case 'toggle':
                return <p className="text-gray-700">{value ? 'Yes' : 'No'}</p>;
            case 'text':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'tel':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'textArea':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'image':
                return (
                    <div className="mt-2">
                        {value ? (
                            <ImageViewer>
                                <img className='w-64 h-64' src={value}  alt="" />
                            </ImageViewer>
                        ) : (
                            <p className="text-gray-700">No image uploaded</p>
                        )}
                    </div>
                );
            case 'radio':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'select':
                return <p className="text-gray-700">{field.selectOptions.find(opt => opt.value === value)?.label || 'N/A'}</p>;
            case 'number':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            default:
                return <p className="text-gray-700">No data available</p>;
        }
    };

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return (
            <div className="p-4 text-red-600">
                {error}
            </div>
        )
    }

    return (
        <div className="bg-gray-100 min-h-screen font-sans font-poppins">
            {/* Header */}
            <header className="bg-[#e6f0ff] text-[#3b5aa9] py-6 shadow-md">
                <div className="container mx-auto flex items-center justify-between px-6">
                    <img className="w-24 h-auto" src="/logoWithNameDark.png" alt="Logo" />
                    <h1 className="text-2xl font-semibold">{`${data[0]?.ticket_id || ""} ${data[0]?.account_id || ""} ${data[0]?.customer_name || ""} ${data[0]?.site_name || ""}`}</h1>
                    <button
                        onClick={() => getSignOutCode(id)}
                        className="py-2 px-6 bg-[#3b5aa9] text-white font-semibold rounded-lg shadow-md hover:bg-[#2a487a] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9] focus:ring-offset-2 transition ease-in-out duration-150"
                    >
                        Generate Code
                    </button>
                </div>
            </header>

            <div className="p-8">
                {/* Tabs */}
                <div className="mb-6 border-b border-gray-300">
                    <nav className="flex space-x-4">
                        {data.map((item) => (
                            <button
                                key={item.id}
                                className={`py-2 px-4 rounded-t-lg text-sm font-semibold transition ease-in-out duration-150 ${activeTab === item.id ? 'bg-[#3b5aa9] text-white' : 'text-[#3b5aa9]'} hover:bg-[#e6f0ff] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]`}
                                onClick={() => setActiveTab(item.id)}
                            >
                                {item.title}
                            </button>
                        ))}
                    </nav>
                </div>

                <div className="space-y-6">
                    <div className="flex items-center space-x-4 mb-4">
                        <div className="relative">
                            <select
                                value={statusMap[activeTab]}
                                onChange={(e) => handleStatusChange(activeTab, e.target.value)}
                                className="block w-full py-2 pl-3 pr-10 border border-gray-300 rounded-lg shadow-lg bg-white text-gray-700 focus:outline-none focus:ring-1 focus:ring-[#3b5aa9] transition ease-in-out duration-150 appearance-none"
                            >
                                {statusOptions.map(option => (
                                    <option key={option.label} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <svg className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                        {/* <button
                            onClick={() => downloadPDF(activeTab)}
                            className="py-2 px-4 bg-[#3b5aa9] text-white font-semibold rounded-lg shadow-md hover:bg-[#2a487a] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9] focus:ring-offset-2 transition ease-in-out duration-150"
                        >
                            Download PDF
                        </button> */}
                    </div>
                    {data.find((item) => item.id === activeTab)?.submission_data?.map((submission, index) => (
                        <div key={index} className="p-6 border border-gray-300 rounded-lg shadow-lg bg-white">
                            <h2 className="text-xl font-bold mb-4 text-gray-800">{nameMapping[data.find((item) => item.id === activeTab)?.name] || 'Line'} {index + 1}</h2>
                            <div className="space-y-4">
                                {Object.keys(data.find((item) => item.id === activeTab)?.form_schema || {}).map((key) => {
                                    const field = data.find((item) => item.id === activeTab)?.form_schema[key];
                                    const value = submission[key] || submission[key.split('-')[1]];
                                    if(field?.type !== 'toggle' && !value){
                                        return null
                                    }
                                    return (
                                        <div key={key} className="flex flex-col">
                                            <span className="font-bold text-gray-700">{field.label}</span>
                                            <div className="mt-2">
                                                {renderFieldValue(field, value)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

                    {isModalOpen && (
                                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                                <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                                    <h2 className="text-xl font-semibold text-[#3b5aa9] mb-4">Survey Code</h2>
                                    <p className="text-gray-700">{surveyCode}</p>
                                    <div className="mt-6 flex justify-between">
                                        <button
                                            onClick={() => setIsModalOpen(false)}
                                            className="mt-4 bg-[#3b5aa9] text-white px-4 py-2 rounded-lg hover:bg-[#2a4579] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
                                        >
                                            Close
                                        </button>
                                        <button
                                            onClick={() => getConfirmCode(id)}
                                            className="mt-4 bg-[#3b5aa9] text-white px-4 py-2 rounded-lg hover:bg-[#2a4579] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                    )}
        </div>
    );
};

export default Report;
