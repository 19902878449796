import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "components/common";
import { useAuthState } from "contexts/auth";
import { classNames } from "utils";
import { useForm } from "react-hook-form";
import { renderEditComponent, renderViewControl } from "utils/formHelpers";
import * as loaders from "utils/loaders";
import { toast } from "react-toastify";
import Spinner from 'components/common/Spinner';
import { ImageViewer } from "react-image-viewer-dv"
import { getSubmittedFormPm, savePmForm, getSurveyFormsPm, createSurveyTicket } from "api";
import useSockette from "hooks/useNewWebSocket";

const EditForm = ({surveyInstanceId}) => {
    const { id: surveyInstanceIdParam } = useParams();

    const [id, setId] = useState(surveyInstanceIdParam || surveyInstanceId)
    const navigate = useNavigate();
    const { userHasAccess } = useAuthState();

    let {data: socketData, error: socketError, isConnected, sendMessage} = useSockette(`${process.env.REACT_APP_BACKEND_BASE_URL}/v3/real-time?ticket=${id}`);

    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState(0); 
    const [activeLineTab, setActiveLineTab] = useState(0);
    const [activeTab, setActiveTab] = useState({}); 
    const [dynamicOptions, setDynamicOptions] = useState({});
    const [formData, setFormData] = useState(null);
    const [preFilledData, setPreFilledData] = useState({});

    async function getSubmittedForm(instanceId) {
        try {
            setLoading(true)
            const response = await getSubmittedFormPm(instanceId)
            setData(response?.data?.data); 
            setPreFilledData(response?.data?.formPrefillData); 
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    }

    async function getForm() {
        try {
            setLoading(true)
            const response = await getSurveyFormsPm();
            setForm(response?.data[0]?.form_schema);
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    }

    async function submitForm(formData) {
        try {
            setLoading(true)
            const {data} = await savePmForm(id, formData)

            if(data?.message !== 'Form submitted' && data?.message !== 'Submission Updated'){
                setLoading(false)
                toast.error(`Unable to save data`)
            }

            setLoading(false)
            toast.success('Data Saved')
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)

            toast.error(`Unable to save data`)
        }
    }

    async function createTicket() {
        try {
            setLoading(true)
            const {data} = await createSurveyTicket(id)
            
            if(data?.message !== 'Ticket Created'){
                setLoading(false)
                toast.error(`Unable to create ticket`)
            }

            setLoading(false)
            toast.success('Ticket Created')
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)

            toast.error(`Unable to create ticket`)
        }
    }

    const {
        control,
        register,
        reset,
        trigger,
        getValues,
        watch,
        formState: { errors },
      } = useForm();

    useEffect(() => {
        getSubmittedForm(id);
        getForm();
    }, [id]);

    useEffect(() => {
        getSubmittedForm(id);
        getForm();
    }, [socketData])

    useEffect(() => {
        reset(preFilledData)
    },[data])

    useEffect(() => {
        setActiveLineTab(0)
    }, [activeCardTab])

    const onSave = async () => {
        setLoading(true)
        const values = getValues();
        submitForm(values)
        setLoading(false)

    };

    const renderFieldValue = (field, value) => {
        switch (field.type) {
            case 'toggle':
                return <p className="text-gray-700">{value ? 'Yes' : 'No'}</p>;
            case 'text':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'tel':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'textArea':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'image':
                return (
                    <div className="mt-2">
                        {value ? (
                            <ImageViewer>
                                <img className='w-64 h-64' src={value}  alt="" />
                            </ImageViewer>
                        ) : (
                            <p className="text-gray-700">No image uploaded</p>
                        )}
                    </div>
                );
            case 'radio':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            case 'select':
                return <p className="text-gray-700">{field.selectOptions.find(opt => opt.value === value)?.label || 'N/A'}</p>;
            case 'number':
                return <p className="text-gray-700">{value || 'N/A'}</p>;
            default:
                return <p className="text-gray-700">No data available</p>;
        }
    };

    useEffect(() => {
        if (form) {
          const selects = Object.entries(form)
            .filter(([_, value]) => value.type === "select" && value.loader)
            .map(([key, value]) => ({ ...value, key }));
    
          selects.forEach((x) => {
            const isAsync = loaders[x.loader].constructor.name === "AsyncFunction";
            if (isAsync) {
              loaders[x.loader]().then((response) => {
                setDynamicOptions((prevState) => ({
                  ...prevState,
                  [x.key]: response,
                }));
              });
            } else {
              const response = loaders[x.loader]();
              setDynamicOptions((prevState) => ({
                ...prevState,
                [x.key]: response,
              }));
            }
          });
          reset(formData);
        }
      }, [form]);

    
    
    if (loading) {
        return <Spinner />
    }

    return (
        <div className="min-h-screen shadow font-poppins">
            <div className="sticky top-0 z-10 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
                <div className="flex-1">
                    <h3 className="text-lg font-medium leading-6 text-header dark:text-header-dark">
                        {data[0]?.ticket_data?.company?.name || ""}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {data[0]?.ticket_data.siteName?.split('-')[0]?.trim() || data[0]?.ticket_data.site?.name.split('-')[0]?.trim()}
                    </p>
                </div>
                <div className="flex items-center gap-4">
                    <Button
                        variant="secondary"
                        size="small"
                        className="mr-2 w-26"
                        onClick={() => createTicket()}
                    >
                        Create Ticket
                    </Button>
                    <Button
                        variant="primary"
                        size="small"
                        className="w-24"
                        onClick={onSave}
                        isLoading={false}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <div className="p-2 sm:p-3">
            <div className="relative rounded-lg bg-background-primary p-3 dark:bg-background-primary-dark">
          <form>
            <dl className="grid grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-8">
              {form &&
                Object.entries(form).map(([key, value]) => {
                    return renderEditComponent(
                      key,
                      value,
                      register,
                      control,
                      errors,
                      dynamicOptions
                    );
                })}
            </dl>
          </form>
         {/* {watch('line') && watch('line')?.length && (
            renderViewControl('line', form['line'], watch('line'))
         )
         } */}
        </div>
        </div>
        <div className="p-4">
                <div className="border-b border-gray-300 mb-6">
                    <nav className="flex space-x-4">
                        {data?.map((item, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveCardTab(index)}
                                className={classNames(
                                    "py-2 px-4",
                                    activeCardTab === index ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                                )}
                            >
                                {item.name}
                            </button>
                        ))}
                    </nav>
                </div>
                {data.length > 0 && (
                    <div className="mb-6">
                        <div className="p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
                            <div className="border-b border-gray-300">
                                <h3 className="text-lg font-semibold mb-4 text-gray-900">{data[activeCardTab].name}</h3>
                                <nav className="flex space-x-4">
                                    {data[activeCardTab].submission_data.map((_, tabIndex) => (
                                        <button
                                            key={tabIndex}
                                            onClick={() => setActiveLineTab(tabIndex)}
                                            className={classNames(
                                                "py-2 px-4",
                                                activeLineTab === tabIndex ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                                            )}
                                        >
                                            Line {tabIndex + 1}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                            <div className="p-2">
                                {data[activeCardTab].submission_data[activeLineTab] && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-4 text-gray-800">Line {activeLineTab + 1}</h2>
                                        <div className="space-y-4">
                                            {Object.keys(data[activeCardTab].form_schema || {}).map((key) => {
                                                const field = data[activeCardTab].form_schema[key];
                                                const value = data[activeCardTab].submission_data[activeLineTab][key] || data[activeCardTab].submission_data[activeLineTab][key.split('-')[1]];
                                                if(field?.type !== 'toggle' && !value){
                                                    return null
                                                }
                                                return (
                                                    <div key={key} className="flex flex-col">
                                                        <span className="font-bold text-gray-700">{field.label}</span>
                                                        <div className="mt-2">
                                                            {renderFieldValue(field, value)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditForm;
